@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@font-face {
  font-family: 'Proxima Nova Regular';
  src: url(/static/media/ProximaNova-Regular.d97f8f32.eot);
  src: url(/static/media/ProximaNova-Regular.d97f8f32.eot?#iefix) format('embedded-opentype'),
  url(/static/media/ProximaNova-Regular.99528640.woff) format('woff'),
  url(/static/media/ProximaNova-Regular.b078618b.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  }

body {
  margin: 0;
  font-family: 'Proxima Nova Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*, *::before, *::after {
  box-sizing: content-box;
}

a {
  color: black;
}


textarea.ant-input {
  max-width: 100%;
  height: 165px;
}

.ant-table-thead > tr > th {
  font-weight: 750;
}

.App {
}

